window['customerDashboard'] = function (data, callback, scope) {
  window['getSparkColors']();
  window['counters'](data.counters);

  if(data.users_active){
    var labels_users_active = data.users_active.map(function(i){return i[0].split('-')[1]});
    var data_users_active = data.users_active.map(function(i){return i[1]})
  }else{
    this.labels_users_active = []
    var data_users_active = []
  }
  
  if(data.users_inactive){
    var labels_users_inactive = data.users_inactive.map(function(i){return i[0].split('-')[1]});
    var data_users_inactive = data.users_inactive.map(function(i){return i[1]})
  }else{
    this.labels_users_inactive = []
    var data_users_inactive = []
  }
  
  if(data.post_by_months){
    var labels_post_by_months = data.post_by_months.map(function(i){return i[0].split('-')[1]});
    var data_post_by_months = data.post_by_months.map(function(i){return i[1]})
  }else{
    this.labels_post_by_months = []
    var data_post_by_months = []
  }
  
  window['sparkLines']('#spark1', data_users_active, labels_users_active, callback, 'users_active', scope);
  window['sparkLines']('#spark4', data_users_inactive, labels_users_inactive, callback, 'users_inactive', scope);
  window['sparkBar']('#spark2', data_post_by_months, labels_post_by_months, callback, 'post_by_months', scope);
}

window['customerPostsDashboard'] = function(report_data){
  window['getSparkColors']();
  var color1 = window['sparkColors']['color1'];
  var color2 = window['sparkColors']['color2'];
  var color3 = window['sparkColors']['color3'];
  
  var data = []
  data.push({label: 'Correios', data: report_data['correios']});
  data.push({label: 'Flash Courier', data: report_data['flash_courier']});

  window['$'].plot('#top-sales', data, {
    series: {
      pie: { radius: 0.75, innerRadius: 0.58, show: true, highlight: { opacity: 0.1 }, label: { show: false } }
    },
    grid:{ hoverable: true, },
    legend:{ show: false },
    colors: [color2, color1]
  });

  //Chart legend color setter
  window['$']('[data-color="top-sales-color1"]').css({'background-color':color1});
  window['$']('[data-color="top-sales-color2"]').css({'background-color':color2});
  window['$']('[data-color="top-sales-color3"]').css({'background-color':color3});
}

window['customerPostOcurrences'] = function(report_data){
  window['getSparkColors']();
  var color3 = window['sparkColors']['color1'];
  var color4 = window['sparkColors']['color3'];
  
  var data = []
  data.push({label: 'Quantidade pronta para envio', data: report_data['ready_for_shipping']});
  data.push({label: 'Ocorrências finalizadoras', data: report_data['occurrences_completed']});

  var data_2 = []
  data_2.push({label: 'No prazo', data: report_data['openeds']['open_on_deadline']});
  data_2.push({label: 'Fora do prazo', data: report_data['openeds']['open_expired']});

  var data_3 = []
  data_3.push({label: 'No prazo', data: report_data['delivereds']['delivered_on_deadline']});
  data_3.push({label: 'Fora do prazo', data: report_data['delivereds']['delivered_expired']});

  window['$'].plot('#top-sales', data, {
    series: {
      pie: { radius: 0.75, innerRadius: 0.58, show: true, highlight: { opacity: 0.1 }, label: { show: false } }
    },
    grid:{ hoverable: true, },
    legend:{ show: false },
    colors: [color3, color4]
  });

  window['$'].plot('#openeds', data_2, {
    series: {
      pie: { radius: 0.75, innerRadius: 0.58, show: true, highlight: { opacity: 0.1 }, label: { show: false } }
    },
    grid:{ hoverable: true, },
    legend:{ show: false },
    colors: [color4, window['sparkColors']['color4']]
  });

  window['$'].plot('#delivereds', data_3, {
    series: {
      pie: { radius: 0.75, innerRadius: 0.58, show: true, highlight: { opacity: 0.1 }, label: { show: false } }
    },
    grid:{ hoverable: true, },
    legend:{ show: false },
    colors: [color4, window['sparkColors']['color4']]
  });

  //Chart legend color setter;
  window['$']('[data-color="top-sales-color3"]').css({'background-color':color3});
  window['$']('[data-color="top-sales-color4"]').css({'background-color':color4});
  window['$']('[data-color="top-sales-red"]').css({'background-color':window['sparkColors']['color4']});
}