window['CountUp'] = CountUp;
window['Morris'] = Morris;
window['getSparkColors'] = function(){
  return window['sparkColors'] = {
    'color1': App.color.primary,
    'color2': App.color.warning,
    'color3': App.color.success,
    'color4': App.color.danger
  }
}

window['counters'] = function(counters){
  if(counters && counters.length){
    for(let aux = 0; aux < counters.length; aux++){
      if(counters[aux][1] > 0){
        var _el = $('#' + counters[aux][0]);
        var prefix = '';
        var suffix = '';
        var start = 0;
        var end = counters[aux][1];
        var decimals = 0;
        var duration = 2.5;
        var count = new CountUp(_el.get(0), start, end, decimals, duration, {
          suffix: suffix,
          prefix: prefix,
        });
        count.start();
      }
    }
  }else{
    0
  }
}

window['sparkBar'] = function(report_idenfier, data, labels, callback, report_param, scope){
  $(report_idenfier).sparkline(data, {
    type: 'bar',
    width: '85',
    height: '35',
    barWidth: 3,
    barSpacing: 3,
    chartRangeMin: 0,
    tooltipFormat: '{{offset:months}} - {{value}}',
    tooltipValueLookups: {
      'months': labels
    },
    barColor: window['sparkColors']['color2']
  });

  $(report_idenfier).bind('sparklineClick', function(ev) {
    var sparkline = ev.sparklines[0], region = sparkline.getCurrentRegionFields();
    if(region && region.length > 0)
      callback(report_param, labels[region[0].offset], scope);
  });
}

window['sparkLines'] = function(report_idenfier, report, labels, callback, report_param, scope){
  $(report_idenfier).sparkline(report, {
    width: '85',
    height: '35',
    lineColor: window['sparkColors']['color1'],
    highlightSpotColor: window['sparkColors']['color1'],
    highlightLineColor: window['sparkColors']['color1'],
    fillColor: false,
    spotColor: false,
    minSpotColor: false,
    maxSpotColor: false,
    lineWidth: 1.15,
    tooltipFormat: '{{x:months}} - {{y}}',
    tooltipValueLookups: {
      'months': labels
    }
  });

  $(report_idenfier).bind('sparklineClick', function(ev) {
    var sparkline = ev.sparklines[0], region = sparkline.getCurrentRegionFields();
    callback(report_param, labels[region.x], scope);
  });
}
