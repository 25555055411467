window['adminUserDashboard'] = function (data, callback, scope) {
  window['getSparkColors']();
  window['counters'](data.counters);

  var labels_customers_last_month = data.customers_last_month.map(function(i){return i[0]});
  var labels_carrier_changes = data.carrier_changes.map(function(i){return i[0]});

  window['sparkLines']('#opened_customers', data.customers_last_month.map(function(i){return i[1]}), labels_customers_last_month, callback, 'opened_customers', scope);
  window['sparkBar']('#carrier_changes', data.carrier_changes.map(function(i){return i[1]}), labels_carrier_changes, callback, 'carrier_changes', scope);
}
